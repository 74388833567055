<template>
  <div class="app-container">
    <CrudTable ref="table" entity="HermesZone" :columns="columns" />
  </div>
</template>

<script>
import crudIndexMixin from '@/mixins/crud-index';
import CrudTable from '@/components/crud/CrudTable';
export default {
  components: {
    CrudTable
  },
  mixins: [crudIndexMixin],
  data() {
    return {
      columns: [
        { field: 'id' },
        { field: 'zone', label: 'hermes.zone' },
        { field: 'tariffZone', label: 'hermes.tariffZone' },
        { field: 'description' }
      ]
    };
  }
};
</script>

<style scoped></style>
